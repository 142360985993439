.bar{
    display: flex;
    justify-content: left;
    margin: 30px;

}

.bar * {
    width: 50px;
    margin: 10px;
    padding: 10px;
    border-radius: 20%;
    background: white;
    text-align: center;

}


.inactive{
    background: lightgray;
    color: darkgrey;
}

.inactive :hover{
    color: inherit ;
    background: inherit;
}

.bar :hover{
    cursor: pointer;
    background: grey;

}
