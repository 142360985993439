body{
    background: navy;
    display: block;
}

.outline{
    width: 110px;
    text-align: center;
    padding: 10px;
    margin: 10px;
    background: white ;
    transition: linear;
    color: black;
    border-radius: 4px;
}

.text-red{
    color: red;
}

.top{
    text-align: left;
}

.bottom{
    text-align: right;
}

.facedown{
    background-image: url(./back_img.jpg);
    background-size: cover;
    color: rgba(0, 0, 0, 0);
}
