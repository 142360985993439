.playingSpace{
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgb(63,111,194);

    height: 100vh;
}

.money{
    color: white;
    padding: 30px;
}
